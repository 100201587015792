import { useState } from "react";
import { requestAuthorization } from "../actions/fps";
import { setToken } from "../util";

export const LoginScreen = () => {

    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const submit = () => {
        setErrorMessage('');
        requestAuthorization(password)
            .then(response => {
                if (response.ok) {
                    setToken(password);
                    window.location.reload();
                } else setErrorMessage('Invalid password');
            });
    }

    return (
        <div className="flex flex-col items-center">
            <p className="mt-4">Enter Password</p>
            <input type="password" className="basic-border" onChange={e => setPassword(e.target.value)} />
            <div className="button bg-lime-500 m-4" onClick={() => submit()}>Submit</div>
            <div className="text-red-500">{errorMessage}</div>
        </div>
        
    );
}