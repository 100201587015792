import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStatus } from "../util";
import { useRef } from "react";

interface ExpandableListProps {
    status: string;
    isExpanded: boolean;
    setExpanded: (status: string | undefined) => void;
    projects: Project[];
    setProject: (project: Project) => void;
    selectedProject: Project | undefined;
}

export const ExpandableList = (props: ExpandableListProps) => {

    const ref = useRef<HTMLDivElement>(null);

    const getBgColor = (item: Project): string => {
        const statusObj = getStatus(item.status);
        if (statusObj === undefined) return '';
        const isSelected = item.name === props.selectedProject?.name;
        let bg = isSelected ? statusObj.twSelected : statusObj.twColor;
        if (isSelected) return bg;
        return bg + ' ' + statusObj.twHover;
    };

    return (
        <div>
            <div
                className='custom-container mb-1 f-row justify-between cursor-pointer'
                onClick={() => {
                    props.setExpanded(props.isExpanded ? undefined : props.status);
                }}
            >
                <p>{props.status} ({props.projects.length})</p>
                <span className={`transition-transform duration-200 ${props.isExpanded ? 'rotate-180' : ''}`}>
                    <FontAwesomeIcon icon={faAngleDown} className='my-auto' />
                </span>
            </div>
            <div
                ref={ref}
                className='transition-all duration-300 ease-in-out overflow-hidden'
                style={{
                    maxHeight: props.isExpanded ? `${ref.current ? ref.current.scrollHeight : '0'}px` : '0px',
                }}
            >
                {props.projects.map((item: Project) => (
                    <div
                        key={item.id}
                        className={getBgColor(item) + ' px-2 py-1 mb-1 rounded-md cursor-pointer basic-border shadow-lg'}
                        onClick={() => {
                            props.setProject(item);
                            sessionStorage.setItem('project', item.id);
                        }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
}