import { faArrowUpRightFromSquare, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prStatuses } from "../util";
import { Dropdown } from "./Dropdown";
import { useEffect, useState } from "react";

interface AffectedRepoProps {
    repo: AffectedRepo | undefined;
    updateAllRepos: (repo: AffectedRepo) => void;
}

interface AffectedRepoSectionProps {
    name: string;
}

interface PRProps {
    pr: PullRequest;
}

export const affectedRepoToString = (repo: AffectedRepo) => {
    return repo.repo + ' (' + repo.branch + ')';
}

export const AffectedRepo = (props: AffectedRepoProps) => {

    const repoName: string = props.repo?.repo ?? '';
    const [allPRs, setAllPRs] = useState<PullRequest[]>([]);

    useEffect(() => {
        setAllPRs(props.repo?.prs ?? []);
    }, [props.repo]);

    useEffect(() => {
        if (!props.repo) return;
        props.updateAllRepos({
            repo: props.repo.repo,
            branch: props.repo.branch,
            prs: allPRs,
        });
    }, [props, allPRs]);

    const AffectedRepoSection = (props: AffectedRepoSectionProps) => {

        const addBlankPR = () => {
            setAllPRs([...allPRs, {
                guid: crypto.randomUUID(),
                id: '',
                target: props.name,
                status: prStatuses[0],
            }]);
            // DON'T update state for surrounding AffectedRepo
        };

        const PR = (props: PRProps) => {
            const [id, setId] = useState<string>(props.pr.id);
            const [status, setStatus] = useState<string>(props.pr.status);

            const updateAllPRs = (s: string) => {
                setStatus(s);
                setAllPRs(allPRs.map(pr => 
                    pr.guid === props.pr.guid ?
                        {...pr, id: id, status: s} : {...pr}
                ));
            };

            return (
                <div className='f-row bg-gray-500/50 rounded-md p-2 items-center'>
                    <input className='basic-border rounded-md px-1 w-16 mr-1' value={id} onChange={e => setId(e.target.value)} onBlur={() => updateAllPRs(status)} />
                    <Dropdown options={prStatuses} value={status} setOption={s => updateAllPRs(s)} above={props.pr.target !== 'Alpha' && props.pr.target !== 'Beta'} />
                    {id.length === 0 ? (
                        <FontAwesomeIcon className='my-auto mx-1 cursor-not-allowed' icon={faArrowUpRightFromSquare} />
                    ) : (
                        <a href={`http://fps-tfs2/DefaultCollection/FPSGit/_git/${repoName}/pullrequest/${id}`} className='my-auto' target='_blank' rel='noreferrer' >
                            <FontAwesomeIcon className='my-auto mx-1 cursor-pointer' icon={faArrowUpRightFromSquare} title='Open in new tab' />
                        </a>
                    )}
                    <FontAwesomeIcon icon={faTrash} className='button-red !py-2 my-auto' onClick={() => setAllPRs(allPRs.filter(pr => pr.guid !== props.pr.guid))} />
                </div>
            );
        };

        return (
            <div className='mb-1'>
                <div className='f-row items-center'>
                    {props.name}<div className='border-t border-black w-full mx-2'></div>
                    <div className='button-green text-sm' title='New PR' onClick={addBlankPR}><FontAwesomeIcon icon={faPlus} /></div>
                </div>
                <div className='f-row justify-between items-center'>
                    <div className='f-row flex-wrap'>
                        {allPRs.filter(pr => pr.target === props.name).map(pr => (
                            <div key={pr.id} className='my-1'>
                                <PR pr={pr} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };
    
    return props.repo ? (
        <div className='f-col h-full pr-2 overflow-y-scroll'>
            <AffectedRepoSection name='Alpha' />
            <AffectedRepoSection name='Beta' />
            <AffectedRepoSection name='Staging' />
            <AffectedRepoSection name='Master' />
        </div>
    ) : (
        <div className='w-full flex justify-center items-center'>Select or add an affected repo above...</div>
    );
}