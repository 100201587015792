import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

export const Dropdown = (props: DropdownProps) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ddRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const handleClickOutside = (event: MouseEvent) => {
            if (ddRef.current && !ddRef.current.contains(event.target as Node)) setIsOpen(false);
          };

        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div ref={ddRef} className={`inline-block relative ${props.width ?? 'w-48'}`}>
            <div
                onClick={toggle}
                className='bg-white rounded-md basic-border px-1.5 cursor-pointer f-row justify-between items-center overflow-hidden'
            >
                {props.value ? props.value : <div className='text-white'>X</div>}
                {isOpen ? (<FontAwesomeIcon className='ml-2' icon={faAngleUp} />) : (<FontAwesomeIcon className='ml-2' icon={faAngleDown} />)}
            </div>
            <div
                ref={listRef}
                className={`absolute bg-gray-200 my-1 transition-all duration-300 ease-in-out ${props.above ? 'bottom-full' : 'top-full'} rounded-md right-0 ${props.height ? props.height + ' overflow-y-scroll' : 'overflow-hidden'} ${isOpen ? 'z-50 basic-border' : 'z-10'}`}
                style={{
                    maxHeight: isOpen ? `${listRef.current ? listRef.current.scrollHeight : '0'}px` : '0px',
                }}
            >
                <ul className='list-none'>
                    {props.options.map(item => (
                        <li key={item} className='hover:bg-green-400 cursor-pointer px-1.5 py-0.5 rounded-md' onClick={() => {
                            toggle();
                            props.setOption(item);
                        }}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}