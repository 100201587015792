import { getToken } from "../util";

const BACKEND_URL = 'https://fps.noahpratt.com';
const authHeader = {'Authorization': getToken() ?? '', 'Content-Type': 'application/json'};

export const requestAuthorization = (password: string) => {
    return fetch(BACKEND_URL + '/auth', {
        headers: {
            'Authorization': password,
        },
    });
}

export const getProjects = () => {
    return fetch(BACKEND_URL + '/api/projects', {
        headers: authHeader,
    });
}

export const postProject = (project: Project) => {
    return fetch(BACKEND_URL + '/api/projects', {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify(project),
    });
}

export const updateProject = (project: Project) => {
    return fetch(BACKEND_URL + '/api/projects', {
        method: 'PUT',
        headers: authHeader,
        body: JSON.stringify(project),
    });
}

export const deleteProject = (id: string) => {
    const encodedId = encodeURIComponent(id);
    return fetch(BACKEND_URL + '/api/projects/' + encodedId, {
        method: 'DELETE',
        headers: authHeader,
    });
}

export const getCardFromURL = (url: string) => {
    const encodedURL = encodeURIComponent(url);
    return fetch(BACKEND_URL + '/api/card/' + encodedURL, {
        headers: authHeader,
    });
}

export const pushCard = (project: Project) => {
    return fetch(BACKEND_URL + '/api/card/' + project.id, {
        method: 'PUT',
        headers: authHeader,
        body: JSON.stringify(project),
    });
}